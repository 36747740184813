import { template as template_cc91dc9cd20949b19a11bda63d1e1a01 } from "@ember/template-compiler";
const FKText = template_cc91dc9cd20949b19a11bda63d1e1a01(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
