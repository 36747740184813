import { template as template_6e8e5fab434a4f9ebcf3c0bd484b3889 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_6e8e5fab434a4f9ebcf3c0bd484b3889(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
