import { template as template_273c74f6adf145678e07c1d2af6cc493 } from "@ember/template-compiler";
const FKControlMenuContainer = template_273c74f6adf145678e07c1d2af6cc493(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
